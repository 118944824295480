import React, { useState } from "react";
import "./insuranceTypes.css";

const InsuranceTypes = () => {
  const [expanded, setExpanded] = useState(1); // Set the initial state to 1

  const options = [
    {
      id: 1,
      title: "Dental",
      image: require("../../Images/products/Dental.png"),
      info: "Some dental insurance plans offer more benefits than others, but come with a bigger price tag. As with any insurance plan, you need to weigh how much coverage you want against how much you are willing to pay.      ",
    },
    {
      id: 2,
      title: "Life Insurance",
      image: require("../../Images/products/Small-Business.png"),
      info: "Some dental insurance plans offer more benefits than others, but come with a bigger price tag. As with any insurance plan, you need to weigh how much coverage you want against how much you are willing to pay.      ",
    },
    {
      id: 3,
      title: "Medicare Suppliment",
      image: require("../../Images/products/Medicare-Supplement.png"),
      info: "The best time to get a Medicare Supplement Plan is during your open enrollment period, which automatically begins the month you turn 65 and are enrolled in Medicare Part B.",
    },
    {
      id: 4,
      title: "Supplemental Accident",
      image: require("../../Images/products/Supplemental-Accident-Coverage.png"),
      info: "No matter how careful you are, accidents can happen. Your health insurance plan will only pay for so much, and the rest is your responsibility. This is where supplemental accident coverage can help. ",
    },
  ];

  const toggleExpand = (id) => {
    setExpanded(expanded === id ? null : id);
  };

  return (
    <div className="options-container">
      {options.map((option) => (
        <div
          key={option.id}
          className="option"
          onClick={() => toggleExpand(option.id)}
        >
          <div className="displayedContent">
            <img src={option.image} alt={option.title} />
            <h1>{option.title}</h1>
          </div>

          <div className="expandedContent">
            {expanded === option.id && <p>{option.info}</p>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default InsuranceTypes;
