import React, { useState } from "react";
import "./values.css";
import innovation from "../../Images/values/innovation.png";
import yourNeeds from "../../Images/values/yourNeeds.png";
import integrity from "../../Images/values/integrity.png";

const OurValues = () => {
  const [expanded, setExpanded] = useState(1);

  const values = [
    {
      id: 1,
      title: "Your Needs",
      image: require("../../Images/values/yourNeeds.png"),
      info: " We understand the stress of finding the right insurance plan. Our number one goal is to meet your insurance needs while saving you money. Our trained agents will listen carefully to your needs and budget, and will find a personalized plan for you.",
    },
    {
      id: 2,
      title: "Transparency",
      image: require("../../Images/values/integrity.png"),
      info: "Prioritize honesty and openness in all dealings with clients, partners, and employees. This value ensures that clients are fully informed about their policies, coverages, and any changes that may affect them, fostering trust and long-term relationships.",
    },
    {
      id: 3,
      title: "Improvement",
      image: require("../../Images/values/innovation.png"),
      info: "Commit to ongoing learning, adaptation, and always looking for ways toenhance your benefits for you and your family. This value ensures the brokerage stays ahead in a competitive market and consistently offers top-tier solutions.",
    },
  ];

  const toggleExpand = (id) => {
    setExpanded(expanded === id ? null : id);
  };

  return (
    <div className="valuesContainer">
      {values.map((value) => (
        <div
          key={value.id}
          className="value"
          onClick={() => toggleExpand(value.id)}
        >
          <div className="displayedValue">
            <img id="valueImage" src={value.image} alt={value.title} />
            <h2>{value.title}</h2>
          </div>
          <div className="expandedValue">
            {expanded === value.id && <p>{value.info}</p>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default OurValues;
