import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import Home from "./Pages/Home/home";
import Services from "./Pages/Services/services";
import Contact from "./Pages/Contact/contact";
import About from "./Pages/About/about";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home></Home>} />
        <Route path="/services" element={<Services></Services>} />
        <Route path="/contact" element={<Contact></Contact>} />
        <Route path="/about" element={<About></About>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
