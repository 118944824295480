import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./layout.css";
import medaLogo from "../../Images/my-meda-health-logo.png";

const Layout = (props) => {
  const { children } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="layout">
      <header className={`menu-bar ${isScrolled ? "collapsed" : ""}`}>
        <div className="logo">
          <img className="medaLogo" src={medaLogo} alt="Meda Logo" />
        </div>
        <nav className={`menu ${isMenuOpen ? "open" : ""}`}>
          <ul>
            <Link to="/">
              <li>
                <a href="#home" onClick={() => setIsMenuOpen(false)}>
                  Home
                </a>
              </li>
            </Link>
            <Link to="/about">
              <li>
                <a href="#about" onClick={() => setIsMenuOpen(false)}>
                  About
                </a>
              </li>
            </Link>
            <Link to="/services">
              <li>
                <a href="#services" onClick={() => setIsMenuOpen(false)}>
                  Services
                </a>
              </li>
            </Link>
            <Link to="/contact">
              <li>
                <a href="#contact" onClick={() => setIsMenuOpen(false)}>
                  Contact
                </a>
              </li>
            </Link>
          </ul>
        </nav>
        <div
          className={`hamburger ${isMenuOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>
      <main>{children}</main>
      <footer>
        <img className="medaLogo" src={medaLogo} alt="Meda Logo" />
        <ul>
          <Link to="/">
            <li>
              <a href="#home">Home</a>
            </li>
          </Link>
          <Link to="/about">
            <li>
              <a href="#about">About</a>
            </li>
          </Link>
          <Link to="/services">
            <li>
              <a href="#services">Services</a>
            </li>
          </Link>
          <Link to="/contact">
            <li>
              <a href="#contact">Contact</a>
            </li>
          </Link>
        </ul>
        <div className="contactInfo">
          <p>4301 Anchor Plaza Pkwy</p>
          <p>STE. 200</p>
          <p>Tampa, FL 33634</p>
          <p>info@mymedahealth.com</p>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
