import React from "react";

import Layout from "../../Components/Layout/layout";
import InsuranceTypes from "../../Components/InsuranceTypes/insuranceTypes";
import HealthOptions from "../../Components/ServicesComponents/healthOptions";

import "./services.css";

function Services() {
  return (
    <Layout>
      <div className="servicesContainer">
        <div className="servicesTop">
          <h2>Our Services</h2>
        </div>
        <div className="servicesMiddle">
          <div className="servicePromises">
            <ul id="lBPromises">
              <li>Fast</li>
              <li>Reliable</li>
              <li>Word</li>
            </ul>
            <ul>
              <li>Fast</li>
              <li>Reliable</li>
              <li>Word</li>
            </ul>
          </div>
          <hr className="servicesBreak"></hr>
          <p className="servicesPromise">
            Our experienced professionals are ready to answer any questions or
            concerns you have, and compare quotes at no cost to you. No pushy
            salespeople hounding you, and no obligations. Take a look at our
            products below.
          </p>
          <h1 id="productTypesH1">Product Types</h1>
          <InsuranceTypes></InsuranceTypes>
        </div>
        <div className="servicesBottom">
          <h1>Health Insurance</h1>
          <HealthOptions></HealthOptions>
        </div>
      </div>
    </Layout>
  );
}

export default Services;
