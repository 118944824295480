import React, { useState } from "react";
import Layout from "../../Components/Layout/layout";
import "./about.css";
import ContactForm from "../../Components/ContactForm/contactForm";
import OurValues from "../../Components/Values/values";

import pic from "../../Images/content/3d.png";
import pic2 from "../../Images/content/3d2.png";

const About = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <Layout>
      <div className="newHomeContainer">
        <div className="heroContainer">
          <div className="heroTop">
            <img className="heroPic" src={pic}></img>
            <div className="heroText">
              <h1>Let us help you protect yourself and those you love</h1>
              <h2>Affordable peace of mind is just a call or click away!</h2>
            </div>
          </div>
          <div className="quoteContainer">
            <ContactForm
              className="splashContactButton"
              modalIsOpen={modalIsOpen}
              closeModal={closeModal}
            />
          </div>
          <a>See what our clients are saying..</a>
        </div>
        <div className="aboutUsContainer">
          <h1>What We Do</h1>
          <div className="aboutUsText">
            <p>
              Looking for insurance can be stressful, because there are so many
              different plans with varying prices and coverage. It requires a
              lot of research to find the perfect plan, but having insurance is
              important, and having an agent by your side is even more
              important.
            </p>
            <p>
              It gives you peace of mind of knowing you’re covered in case of
              the unexpected. If doing all the research begins to feel
              overwhelming, let us help.
            </p>
          </div>
          <img className="heroPic" src={pic2}></img>
        </div>
        <div className="ourValuesContainer">
          <h1>Our Values</h1>
          <OurValues></OurValues>
          <div className="newQuoteBottom">
            <h1>INDIVIDUALS, FAMILIES AND SMALL BUSINESS HEALTH INSURANCE</h1>
            <ContactForm modalIsOpen={modalIsOpen} closeModal={closeModal} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
