import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import useCollapse from "react-collapsed";
import splashVideo from "../../Videos/splashVideo.mp4";
import content2 from "../../Images/content/content2.png";
import OurValues from "../../Components/Values/values";
import ContactForm from "../../Components/ContactForm/contactForm";
import umbrellaIcon from "../../Images/content/umbrellaIcon.png";
import whatWeDo from "../../Images/content/whatWeDo.png";
import videoBG from "../../Videos/videoBG.mp4";

import "./home.css";

import Layout from "../../Components/Layout/layout";
import TestimonialSlider from "../../Components/HomeComponents/testimonialSlider";

function Home() {
  const testimonialsSection = useRef(null);
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <Layout>
      <div className="homeWrapper">
        <div className="homeSplashSection">
          <div className="homeSplashContainer">
            <video
              className="videoBG"
              type="video/mp4"
              src={videoBG}
              playsInline
              muted
              autoPlay
              loop
            ></video>
            <div className="homeSplashContent">
              <h1>
                Let us help you <br></br>protect yourself <br></br> and those
                you love.
              </h1>
              <h2>Affordable peace of mind is just a call or click away!</h2>

              <div className="splashServices">
                <div className="splashServicesLeft">
                  <div id="splashService">
                    <img className="umbrellaIcon" src={umbrellaIcon}></img>
                    <p>Health Insurance</p>
                  </div>
                  <div id="splashService">
                    <img className="umbrellaIcon" src={umbrellaIcon}></img>
                    <p>Dental Insurance</p>
                  </div>
                  <div id="splashService">
                    <img className="umbrellaIcon" src={umbrellaIcon}></img>
                    <p>Supplimental</p>
                  </div>
                </div>
                <div className="splashServicesRight">
                  <div id="splashService">
                    <img className="umbrellaIcon" src={umbrellaIcon}></img>
                    <p>Life Insurance</p>
                  </div>
                  <div id="splashService">
                    <img className="umbrellaIcon" src={umbrellaIcon}></img>
                    <p>Vision Insurance</p>
                  </div>
                  <div id="splashService">
                    <img className="umbrellaIcon" src={umbrellaIcon}></img>
                    <p>Group Coverage</p>
                  </div>
                </div>
              </div>
              <ContactForm
                className="splashContactButton"
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
              />
              <h3 onClick={() => scrollToSection(testimonialsSection)}>
                See what clients are saying..
              </h3>
            </div>
          </div>
        </div>
        <div className="aboutWrapper">
          <h2 id="whiteH1">What We Do</h2>
          <div className="aboutContainer">
            <div className="aboutContent">
              <p>
                Looking for insurance can be stressful, because there are so
                many different plans with varying prices and coverage. It
                requires a lot of research to find the perfect plan, but having
                insurance is important, and having an agent by your side is even
                more important.
              </p>
              <p>
                It gives you peace of mind of knowing you’re covered in case of
                the unexpected. If doing all the research begins to feel
                overwhelming,
              </p>
            </div>
            <div className="aboutPicContainer">
              <img className="whatWeDoPic" src={whatWeDo}></img>
            </div>
          </div>
        </div>

        <div className="valuesWrapper">
          <h1>Our Values</h1>
          <OurValues></OurValues>
        </div>
        {/* <div className="testimonialsWrapper" ref={testimonialsSection}>
          <h1 id="whiteH1">What our Clients have to say..</h1>
          <TestimonialSlider></TestimonialSlider>
        </div> */}
        <div className="quoteBottom">
          <h1>INDIVIDUALS, FAMILIES AND SMALL BUSINESS HEALTH INSURANCE</h1>
          <ContactForm modalIsOpen={modalIsOpen} closeModal={closeModal} />
        </div>
      </div>
    </Layout>
  );
}

export default Home;
